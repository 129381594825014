(function() {

    jQuery(".accordion .panel-title a").click(function (event) {

        var href = jQuery(this).attr("href");
       
        var urlStr = window.location.href;

        var anchor = urlStr.substring(urlStr.indexOf("#") + 1);
    
        console.log(href + " : " + anchor);

        if (href == "#" + anchor || anchor == "") {

            jQuery(".accordion__open-icon").show();
            jQuery(".accordion__close-icon").hide();
            jQuery(".panel-collapse").each(function () {
                jQuery(this).slideUp("fast");

            });

            event.preventDefault();
            history.pushState('', document.title, window.location.pathname);
        } 

       // accordionChangeOpen();

    });

    if (jQuery(".accordion").length != 0) {
        jQuery(window).on("load hashchange", function () {
            //console.log("hit");


            accordionChangeOpen();
        });

    }

}());


function accordionChangeOpen() { 
    var urlStr = window.location.href;
    var anchorDOMid = urlStr.substring(urlStr.indexOf("#") + 1);
    //console.log(anchorDOMid);
    jQuery(".accordion__open-icon").show();
    jQuery(".accordion__close-icon").hide();

        var anchor = "";
        jQuery(".panel-collapse").each(function () {
            jQuery(this).slideUp("fast");



        });
        jQuery(".panel").each(function () {
//            jQuery(this).slideUp("fast");


        });
        var urlStr = window.location.href;
        if (urlStr.indexOf("#") !== -1) {
            var anchor = urlStr.substring(urlStr.indexOf("#") + 1);
            if (jQuery(".accordion").find(".panel-collapse#" + anchor).length != 0) {
                var slider = jQuery(".accordion").find(".panel-collapse#" + anchor);
                if (slider.length != 0) {
                    console.log(anchor);
                    jQuery(slider).slideDown("fast");
                    jQuery(".pnl_" + anchor + " .accordion__open-icon").hide();
                    jQuery(".pnl_" + anchor + " .accordion__close-icon").show();

                }
            }
        
    }









}